import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from './modules/users'
import roles from './modules/roles'
import cashier from './modules/cashier'
import formWizard from './modules/form_wizard'
import profile from './modules/profile'
import bank from './modules/bank'
import brand from './modules/brand'
import item from './modules/item'
import warehouse from './modules/warehouse'
import uom from './modules/uom'
import bankAccounts from './modules/bank_accounts'
import customer from './modules/customer'
import supplier from './modules/supplier'
import parentProduct from './modules/parent_product'
import cart from './modules/cart'
import checkout from './modules/checkout'
import order from './modules/order'
import stockWarehouse from './modules/stock_warehouse'
import stockOpname from './modules/stock_opname'
import returs from './modules/returs'
import tutorial from './modules/tutorial'
import historyTransaction from './modules/history_transaction'
import payout from './modules/payout'
import vouchers from './modules/vouchers'
import batch from './modules/batch'
import cabang from './modules/cabang'
import sales from './modules/sales'
import versioning from './modules/versioning'
import subscribe from './modules/subscribe'
import pettyCash from './modules/petty_cash'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users,
    roles,
    cashier,
    formWizard,
    profile,
    bank,
    brand,
    item,
    warehouse,
    uom,
    bankAccounts,
    customer,
    supplier,
    parentProduct,
    cart,
    checkout,
    order,
    stockWarehouse,
    stockOpname,
    returs,
    tutorial,
    historyTransaction,
    payout,
    vouchers,
    batch,
    cabang,
    sales,
    app,
    appConfig,
    verticalMenu,
    versioning,
    subscribe,
    pettyCash,
  },
  strict: process.env.DEV,
})
