function lazyLoad(view) {
  return () => import(`@/views/master/${view}.vue`);
}
export default [
  {
    path: "/master",
    name: "master-toko.index",
    component: lazyLoad("toko/index"),
    meta: {},
  },
  // {
  //   path: "/master/toko",
  //   name: "master-toko.index",
  //   component: lazyLoad("toko/index"),
  //   meta: {},
  // },
  {
    path: "/master/sales",
    name: "master-sales.index",
    component: lazyLoad("sales/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/produk",
    name: "master-product.index",
    component: lazyLoad("product/index"),
    meta: {},
  },
  {
    path: "/master/produk-induk/:id",
    name: "master-product.detail",
    component: lazyLoad("product/parent"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/master/produk-induk",
    name: "master-product.parent",
    component: lazyLoad("product/parent"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/master/produk-eceran",
    name: "master-product.retail",
    component: lazyLoad("product/retail"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/master/produk-eceran/:id",
    name: "master-product-eceran.detail",
    component: lazyLoad("product/retail"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/master/gudang",
    name: "master-gudang.index",
    component: lazyLoad("gudang/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/users",
    name: "master-users.index",
    component: lazyLoad("users/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/cabang",
    name: "master-cabang.index",
    component: lazyLoad("cabang/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/rekening",
    name: "master-rekening.index",
    component: lazyLoad("rekening/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/billing",
    name: "master-billing.index",
    component: lazyLoad("billing/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/ads",
    name: "ads.index",
    component: lazyLoad("ads/index"),
    meta: {
      navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/master/variation",
    name: "variation.index",
    component: lazyLoad("Variation"),
    meta: {},
  },
];
